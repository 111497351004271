<template>
  <v-row>
    <v-col>
      <v-card class="teros-elevation" style="width: 100%">
        <v-card-title class="px-5 text-uppercase v-title-cadastro">
          Ficha de Inclusão de Projetos
        </v-card-title>
        <v-card-text class="px-5 v-form-cadastro">
          <v-row class="ml-n4 mr-0">
            <input-v
              v-for="(field, index) of fields"
              class="pl-4"
              :colSize="field.colSize"
              :key="index"
              :label="field"
              :opts="opts"
              :valid.sync="field.valid"
              v-model="projeto[field.key]"
            ></input-v>
          </v-row>
        </v-card-text>
      </v-card>
      <v-snackbar v-model="validationNotification" color="error" text outlined>
        <v-icon color="error" left>mdi-alert-circle-outline</v-icon>
        Existem campos inválidos.
      </v-snackbar>
    </v-col>
    <v-col cols="2" style="min-width: 400px" class="d-flex flex-column">
      <v-card class="teros-elevation">
        <v-card-subtitle v-if="hasUploadPending || hasUploadError" class="pb-0">
          <v-alert
            v-if="hasUploadPending"
            color="blue"
            dense
            text
            class="v-label-input mt-1 mb-0"
          >
            Anexos cujo upload não foi concluído são desconsiderados no processo de salvamento.
          </v-alert>
          <v-alert
            v-if="hasUploadError"
            dense
            text
            type="error"
            class="v-label-input mt-1 mb-0"
          >
            Anexos com erro são exibidos aqui, mas são desconsiderados no processo de salvamento.
          </v-alert>
        </v-card-subtitle>
        <v-card-subtitle class="pb-1">
          <uploader-v
            v-model="projeto.anexo"
            v-slot="{ selectFiles }"
            @input="anexosChanged"
          >
            <v-btn
              class="mx-auto d-block px-2"
              dense
              :disabled="!canEdit"
              text
              @click="selectFiles()"
            >
              <v-icon left>mdi-paperclip</v-icon>
              Clique aqui para anexar arquivos
            </v-btn>
          </uploader-v>
        </v-card-subtitle>
        <v-divider></v-divider>
        <v-card-text class="py-0">
          <template v-if="!projeto.anexo.length">
            <v-alert
              border="left"
              class="v-label-input"
              color="primary"
              dense
              outlined
              text
              type="info"
            >
              Não existem anexos.
            </v-alert>
          </template>
          <v-card
            elevation="0"
            class="mb-2"
            v-for="(anexo, akey) in projeto.anexo"
            :key="akey"
          >
            <v-progress-linear
              :color="anexo.error ? 'error' : 'secondary'"
              :value="anexo.percent"
              :striped="anexo.uploading"
              height="2em"
            >
              <v-card-text class="p-2">
                <v-row
                  justify="space-between"
                  align="center"
                  no-gutters
                  class="py-2 pl-2 pr-0"
                >
                  <span
                    class="v-label-input text-truncate"
                    :style="{ width: anexo.uploading ? '260px' : '290px' }"
                  >
                    <v-icon left>
                      {{
                        anexo.error
                          ? "mdi-file-document-alert"
                          : "mdi-file-document"
                      }}
                    </v-icon>
                    <span
                      :title="getAnexoTitle(anexo)"
                    >
                      {{ anexo.error || anexo.nome }}
                    </span>
                  </span>
                  <span v-if="anexo.uploading" class="v-label-input">
                    {{ anexo.percent }}%
                  </span>
                  <v-btn
                    v-if="isClient || !idProjeto"
                    :disabled="!canEdit"
                    small
                    icon
                    @click="projeto.anexo.splice(akey, 1)"
                  >
                    <v-icon small>mdi-close-circle-outline</v-icon>
                  </v-btn>
                  <v-btn v-else small icon :href="anexo.url" target="_blank">
                    <v-icon small>mdi-download</v-icon>
                  </v-btn>
                </v-row>
              </v-card-text>
            </v-progress-linear>
          </v-card>
        </v-card-text>
      </v-card>
      <v-card class="teros-elevation action-buttons-wrap">
        <v-card-actions class="px-5 py-4 flex-wrap justify-content-center">
          <v-tooltip :disabled="canSave" top>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on" class="d-flex">
                <v-btn class="mr-2" color="secondary" depressed :disabled="!canSave || !canEdit" @click="doSave()">
                  Salvar
                </v-btn>
                <v-btn
                  v-if="isClient && showSendButton && !isAgrupado"
                  color="primary"
                  dark
                  depressed
                  :disabled="!canSave || !canEdit"
                  @click="doSend()"
                >
                  Salvar e Enviar
                </v-btn>
              </div>
            </template>
            <span>Não é possivel salvar um projeto sem {{ !projeto.titulo ? 'titulo' : !projeto.ano_base ? 'ano base' : 'campos obrigatórios' }}.</span>
          </v-tooltip>
          <v-tooltip :disabled="canSaveAndApprove" top>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-btn
                  v-if="canApprove && !isAgrupado"
                  color="primary"
                  dark
                  depressed
                  :disabled="!canSaveAndApprove || !canEdit"
                  @click="doSaveAndApprove()"
                >
                  Salvar e Aprovar
                </v-btn>
              </div>
            </template>
            <span>Não é possivel aprovar um projeto sem benefício definido.</span>
          </v-tooltip>
          <template v-if="canApprove && idProjeto && !isAgrupado">
            <v-btn
              class="mt-2"
              color="secondary"
              depressed
              :disabled="!canEdit"
              @click="doReprove()"
            >
              Reprovar
            </v-btn>
            <div />
          </template>
        </v-card-actions>
      </v-card>
    </v-col>

    <FormModal
      :cols="modalFields"
      maxWidth="450px"
      :opened.sync="justificativaModalOpen"
      :opts="opts"
      title="Justificativa de enquadramento"
      :value.sync="justificativaModalData"
      @save="saveJustificativa"
    ></FormModal>

    <g-modal ref="confirmJustifyRepprove" title="Justificativa">
      <p>Deseja preencher uma Justificativa para a reprovação do projeto {{ projeto.titulo }}?</p>

      <template v-slot:buttons="{ close }">
        <v-spacer />
        <v-btn
          class="px-3"
          color="secondary"
          dark
          depressed
          @click="close(false)"
        >
          Não
        </v-btn>
        <v-btn
          class="px-3"
          color="primary"
          dark
          depressed
          @click="close(true)"
        >
          Sim
        </v-btn>
      </template>
    </g-modal>

    <g-modal ref="confirmJustifyApprove" title="Justificativa">
      <p>Deseja preencher uma Justificativa para a aprovação do projeto {{ projeto.titulo }}?</p>

      <template v-slot:buttons="{ close }">
        <v-spacer />
        <v-btn
          class="px-3"
          color="secondary"
          dark
          depressed
          @click="close(false)"
        >
          Não
        </v-btn>
        <v-btn
          class="px-3"
          color="primary"
          dark
          depressed
          @click="close(true)"
        >
          Sim
        </v-btn>
      </template>
    </g-modal>

    <g-modal ref="shouldChangeGroupDate" title="Ajustar data">
      <p class="should-change-group-date">Deseja ajustar as datas do Agrupamento?</p>

      <template v-slot:buttons="{ close }">
        <v-spacer />
        <v-btn
          class="px-3 should-change-group-date"
          color="secondary"
          dark
          depressed
          @click="close('cancel')"
        >
          Cancelar
        </v-btn>
        <v-btn
          class="px-3 should-change-group-date"
          color="secondary"
          dark
          depressed
          @click="close('no')"
        >
          Não
        </v-btn>
        <v-btn
          class="px-3 should-change-group-date"
          color="primary"
          dark
          depressed
          @click="close('yes')"
        >
          Sim
        </v-btn>
        <v-spacer />
      </template>
    </g-modal>
  </v-row>
</template>

<script>
import { BeneficiosEnum, BeneficiosLabels } from "@/core/enums/beneficios";
import { StatusProjetoEnum } from "@/core/enums/projetos";
import { AnyAdmin, AnyClient, UserTypeEnum } from '@/core/enums/user-types';
import { projectsOptions } from "@/helpers/yearsOptions";
import { mapGetters } from "vuex";
import { cloneDeep, isError } from 'lodash';
import { projetoAnaliseFields } from './projetos.analise.fields';

export default {
  components: {
    'g-modal': () => import("@/components/g-modal.vue"),
    "input-v": () => import("@/components/input-v.vue"),
    "uploader-v": () => import("@/components/uploader-v.vue"),
    FormModal: () => import("@/components/form-modal.vue"),
  },
  computed: {
    ...mapGetters(["clientId", "user"]),
    isClient: function () {
      return [UserTypeEnum.GERENCIAL_CLIENTE, UserTypeEnum.COLABORADOR].includes(this.user.tipo_usuario);
    },
    isReproved: function () {
      return this.projeto.status === StatusProjetoEnum.NAO_ENQUADRADO;
    },
    canApprove: function () {
      return [UserTypeEnum.ADMINISTRADOR, UserTypeEnum.CONSULTOR, UserTypeEnum.CLIENTE_ADMINISTRADOR, UserTypeEnum.CLIENTE_CONSULTOR].includes(this.user.tipo_usuario);
    },
    isUser: function() {
      return AnyClient.includes(this.user.tipo_usuario)
    },
    disabledBtnTitle: function () {
      return !this.projeto.trabalho ? 'Não é possivel aprovar um projeto sem benefício definido.' : undefined;
    },
    canEdit: function () {
      const { status } = this.projeto;
      const { tipo_usuario } = this.user;
      // Se 'Em Revisão Galapos' ou 'Em validação final Galapos' somente Galapos pode editar
      // Aprovado para Submissão ou Submetido - ninguém pode editar, mas consultor ainda pode alterar o status
      return ![StatusProjetoEnum.APROVADO_SUBMISSAO, StatusProjetoEnum.SUBMETIDO].includes(status) &&
        (![StatusProjetoEnum.REVISAO_GALAPOS, StatusProjetoEnum.VALIDACAO_FINAL_GALAPOS].includes(status) || AnyAdmin.includes(tipo_usuario));
    },
    canEditStatus: function () {
      const { status } = this.projeto;
      const { tipo_usuario } = this.user;

      // Usuários clientes não pode alterar status
      // Não pode alterar status de agrupamentos
      // Não pode alterar manualmente status Não Enquadrado
      // Aprovado para Submissão ou Submetido - ninguém pode editar, mas consultor ainda pode alterar o status
      return !this.isClient && !this.isReproved && !this.isAgrupado && (this.canEdit || ([StatusProjetoEnum.APROVADO_SUBMISSAO, StatusProjetoEnum.SUBMETIDO].includes(status) && AnyAdmin.includes(tipo_usuario)));
    },
    canSave: function () {
      const { ano_base, titulo } = this.projeto;
      return !!ano_base && !!titulo;
    },
    canSaveAndApprove: function () {
      return this.canSave && !!this.projeto.trabalho;
    },
    fields: function () {
      projetoAnaliseFields.forEach((field) => {
        field.editable = this.canEdit;

        if (['agrupamento_titulo', 'ano_base', 'trabalho', 'data_inicio', 'data_fim', 'status'].includes(field.key)) {
          field.editable &&= !this.isAgrupado;
        }

        if (field.key === 'agrupamento_titulo') {
          field.hideInForm = !this.isAgrupado;
          field.editable = false;
        } else if (field.key === 'status') {
          field.editable = this.canEditStatus;
        } else if (['ano_base', 'trabalho'].includes(field.key)) {
          field.editable = !this.isAgrupado;
        } else if (['data_inicio', 'data_fim'].includes(field.key)) {
          field.editable = true;
        }

        if (field.key === 'justificativa') {
          field.editable = !this.isUser;
          field.hideInForm = this.isCreate && this.isUser;
        }

        if(!this.isClient && !['ano_base', 'titulo'].includes(field.key)) {
          field.rules = [];
        }
      });

      return cloneDeep(projetoAnaliseFields).filter(({ hideInForm }) => !hideInForm);
    },
    idProjeto: function () {
      return !this.isCreate
        ? this.$route.params.projetoId
        : null;
    },
    isCreate: function () {
      return this.$route.meta.mode === "create";
    },
    isAgrupado: function () {
      return !!this.projeto.agrupamento_id;
    },
    resourceProjetos: function () {
      return this.apiResource(`/v1/projetos/${this.clientId}/projeto`);
    },
    resourceStatus: function () {
      return this.apiResource(`/v1/projetos/${this.clientId}/status`);
    },
    showSendButton: function () {
      return !this.projeto?.status || this.projeto.status === StatusProjetoEnum.PENDENTE_ENVIO_ANALISE;
    },
    hasUploadPending: function () {
      return !!this.anexos?.some(({ uploading }) => !!uploading);
    },
    hasUploadError: function () {
      return !!this.anexos?.some(({ error }) => error);
    },
    hasStatus: function() {
      return this.opts.statusOpts.length > 0;
    },
    hasChangedDate() {
      const { data_inicio: oldIni, data_fim: oldFim } = this.originalProject;
      const { data_inicio: newIni, data_fim: newFim } = this.projeto;
      return oldIni !== newIni || oldFim !== newFim;
    },
  },
  created: function () {
    if (this.idProjeto) {
      this.resourceProjetos.get(this.idProjeto).then((response) => {
        this.originalProject = cloneDeep(response.projeto);
        this.projeto = response.projeto;
        this.projeto.anexo = !this.projeto.anexo || this.projeto.anexo == ""
          ? []
          : JSON.parse(this.projeto.anexo);
        const cliente = response.clientes;

        if (
          cliente.trabalho != BeneficiosEnum.LEI_DO_BEM_E_INFORMATICA &&
          this.projeto.trabalho != cliente.trabalho
        ) {
          this.projeto.trabalho = cliente.trabalho;
        }
      });
    }

    this.resourceStatus.get().then((response) => {
      this.opts.statusOpts = response.sort((a, b) => a.ordem - b.ordem).reduce((acc, curr) => {
        if (!curr.label) {
          return acc;
        }

        if (![
            StatusProjetoEnum.PENDENTE_ANALISE,
            StatusProjetoEnum.ATRASO,
            StatusProjetoEnum.INFORMACOES_PENDENTES,
          ].includes(curr.id)) {
          curr.disabled = true;
        }

        return [...acc, curr];
      }, []);
    });
  },
  methods: {
    alterarSituacaoProjetos: function (aprovar = false) {
      const url = `/v1/projetos/${this.clientId}/analise/${
        aprovar ? "aprovado" : "reprovado"
      }?projetoIds=${[this.projeto.id]}`;
      return this.apiResource(url).save({ justificativa: this.projeto.justificativa, idProjetosJustificados: [this.projeto.id] });
    },
    getAnexoTitle: function (anexo) {
      let title = anexo.nome;

      if (anexo.error) {
        title += `\n${anexo.error}`;
      }

      return title;
    },
    isValidForm: function () {
      let invalid = Object.entries(this.fields)
        .map((s) => s[1])
        .filter((i) => !i.hideInForm && i.editable !== false && i.valid)
        .map((l) => {
          return l.valid() ? "VALIDO" : "INVÁLIDO";
        })
        .find((valid) => valid == "INVÁLIDO");
      return !invalid;
    },
    doSave: async function (skipRedirect = false) {
      try {
        if (!this.isValidForm()) {
          throw new Error('Um ou mais campos do formulário não são válidos');
        }

        if (this.isAgrupado && this.hasChangedDate) {
          const action = await this.$refs.shouldChangeGroupDate.asyncOpen();

          switch (action) {
            case 'yes':
              this.projeto.precisa_ajustar_datas_agrupamento = true;
              break;
            case 'no':
              break;
            default:
              return;
          }
        }

        const projeto = cloneDeep(this.projeto);
        projeto.empresaId = this.clientId;
        projeto.anexo = JSON.stringify(projeto.anexo.filter(({ uploading, error }) => !uploading && !error));
        const response = await this.resourceProjetos.save(projeto, projeto.id);

        if (response.error) {
          throw response;
        }

        this.$notify({
          group: "geral",
          duration: 5000,
          type: "success",
          title: "Sucesso",
          text: "Seu projeto foi salvo.",
        });

        if (skipRedirect) {
          return response;
        }

        if (this.isCreate) {
          this.$router.push({ name: "ficha-projeto-analise", params: { projetoId: response.id } });
        } else {
          this.$router.go(0);
        }
      } catch (error) {
        this.validationNotification = true;
        return error;
      }
    },
    doSaveAndApprove: function () {
      this.doApprove();
    },
    doSend: function () {
      this.showConfirm(
        "Deseja realmente enviar seu projeto?",
        `Você confirma que o projeto <b>${this.projeto.titulo}</b> deverá ser enviado para análise?`,
        () => {
          this.projeto.status = StatusProjetoEnum.PENDENTE_ANALISE;
          this.doSave();
        },
      );
    },
    doApprove: function () {
      this.showConfirm(
        "Confirmar Aprovação",
        `Você confirma que o projeto <b>${this.projeto.titulo}</b> está de acordo com os manuais internacionais de inovação e com os conceitos legais atualmente vigentes?`,
        () => {
          this.showJustifyConfirmApprove();
        }
      );
    },
    doReprove: function () {
      this.showConfirm(
        'Confirmar "Não Enquadramento"',
        `Você confirma que o projeto <b>${this.projeto.titulo}</b> deverá ser reprovado?`,
        () => {
          this.showJustifyConfirmRepprove();
        }
      );
    },
    anexosChanged: function (nextAnexos) {
      this.anexos = cloneDeep(nextAnexos);
    },
    showJustifyConfirmRepprove() {
      if(!this.projeto.justificativa) {
        return this.$refs.confirmJustifyRepprove.asyncOpen().then((confirm) => {
          if (confirm) {
            this.justificativaModalData.justificativa = this.projeto.justificativa;
            this.justificativaModalData.isApproved = false;
            return this.justificativaModalOpen = true;
          }
          return this.alteraProjeto(false);
        })
      }
      return this.alteraProjeto(false);
    },
    showJustifyConfirmApprove() {
      if(!this.projeto.justificativa) {
        return this.$refs.confirmJustifyApprove.asyncOpen().then((confirm) => {
            if (confirm) {
              this.justificativaModalData.justificativa = this.projeto.justificativa;
              this.justificativaModalData.isApproved = true;
              return this.justificativaModalOpen = true;
            }
            return this.alteraProjeto(true);
        })
      }
      return this.alteraProjeto(true);
    },
    alteraProjeto(isEnquadrado) {
      this.doSave(true).then((response) => {
        if(!isError(response) && !response.error) {
          this.projeto.id = response.id;
          if(!isEnquadrado) {
            return this.alterarSituacaoProjetos(false).then(() => {
              this.$notify({
                group: "geral",
                duration: 5000,
                type: "success",
                title: "Sucesso",
                text: 'Projeto marcado como "Não enquadrado"',
              });
              this.$router.go(0)
            });
          }
          return this.alterarSituacaoProjetos(true).then(() => {
            this.$notify({
              group: "geral",
              duration: 5000,
              type: "success",
              title: "Sucesso",
              text: "Projeto Aprovado",
            });

            if (this.isCreate) {
              this.$router.push({ name: "ficha-projeto-analise", params: { projetoId: this.projeto.id } });
            } else {
              this.$router.go(0);
            }
        });
      }
      })
    },
    saveJustificativa: function () {
      this.justificativaModalOpen = false;
      this.projeto.justificativa = this.justificativaModalData.justificativa;
      return this.alteraProjeto(this.justificativaModalData.isApproved)
    },
  },
  data: function () {
    return {
      validationNotification: false,
      anexos: [],
      cliente:{
        trabalho: null
      },
      originalProject: {},
      projeto: {
        id: null,
        empresaId: null,
        ano_base: null,
        status: StatusProjetoEnum.PENDENTE_ENVIO_ANALISE,
        id_projeto: null,
        titulo: null,
        trabalho: 0,
        responsavelTxt: null,
        descricao: null,
        elemento_tecnologico: null,
        desafio_tecnologico: null,
        anexo: [],
      },
      opts: {
        projectsOptions,
        statusOpts: [],
        beneficios: [
          {
            id: 0,
            nome: "Nenhum",
          },
          {
            id: BeneficiosEnum.LEI_DO_BEM,
            nome: BeneficiosLabels.LEI_DO_BEM,
          },
          {
            id: BeneficiosEnum.LEI_DE_INFORMATICA,
            nome: BeneficiosLabels.LEI_DE_INFORMATICA,
          },
          {
            id: BeneficiosEnum.LEI_DO_BEM_E_INFORMATICA,
            nome: BeneficiosLabels.LEI_DO_BEM_E_INFORMATICA,
          },
        ],
      },
      modalFields: [
        {
          key: "justificativa",
          name: "Justificativa",
          type: this.$fieldTypes.TEXTAREA,
        }
      ],
      justificativaModalOpen: false,
      justificativaModalData: {
        id: null,
        justificativa: '',
        isApproved: false,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.v-progress-linear__content {
  .v-btn > .v-btn__content .v-icon,
  .v-label-input {
    &,
    & .theme--light.v-icon {
      color: rgba(255, 255, 255, 0.87);
    }
  }
}

.v-card__actions > {
  div, .v-btn {
    padding: 0;
    margin: 0 .25rem;
    width: calc(50% - 1rem);
  }

  div .v-btn {
    width: 100%;
  }
}

.v-btn.should-change-group-date {
  width: calc(33.333% - 1rem);
}

p.should-change-group-date {
  min-width: 400px;
}

.v-application--is-ltr .v-card__actions > .v-btn.v-btn + .v-btn {
  margin-left: 9px !important;
}

::v-deep {
  .theme--dark.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
    background-color: rgba(#1c1c44, 0.75) !important;
  }

  .theme--dark.v-btn.v-btn--disabled {
    color: rgba(255, 255, 255, 0.75) !important;
  }
}

.action-buttons-wrap {
  position: sticky;
  bottom: 12px;
  margin-top: auto;
}
</style>
